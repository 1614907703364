.header {
  position: static;
  left: 0;
  top: 0;

  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 10rem;

  background: rgb(131, 139, 144);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 50;
}
.NavMenu {
  display: flex;
  align-items: center;
}

.header a.logo {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

@media (max-width: 639px) {
  .logo h1 {
    display: none;
  }
  .header {
    height: 20rem;
  }
  .addressContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addressItem {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .address {
    margin: 2%;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
  }
}

.addressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 31%;
}
.addressItem {
  display: flex;
  align-items: center;
  width: 100%;
}
.address {
  color: #fff;
  /* align-items: center; */
  margin-left: 0.5rem;
  flex-grow: 1;
  margin: 1%;
}

a,
p {
  text-decoration: none;
}
