/* General Styles */
.header {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 6.5rem;
  background: transparent;
  z-index: 50;
}

.NavMenu {
  display: flex;
  align-items: center;
}

.header a {
  display: inline-block;
  padding: 0 1rem;
  color: #fff;
  text-decoration: none;
  line-height: 3.5rem;
}

.header a p:hover,
.header a p:active {
  background: rgba(0, 0, 0, 0.2);
}

.header a.logo {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.borderleft {
  color: #fff;
}

/* Responsive Grid System */
.col-xs-12 {
  width: 100%;
}
@media (min-width: 576px) {
  .col-sm-12 {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-6 {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .col-lg-6 {
    width: 50%;
  }
}

/* Responsive Adjustments */
@media (max-width: 639px) {
  .logo h1 {
    display: none;
  }
  .header {
    flex-direction: column;
    align-items: center;
    height: auto;
    position: static;
    background: #ffcf00;
  }
  .NavMenu {
    flex-direction: column;
  }
  .borderleft {
    display: none;
  }
}

.header nav a.active {
  background: rgba(0, 0, 0, 0.4);
}
